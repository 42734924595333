import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import DevelopmentProcess from "../../components/WEB_DevelopmentProcess"
import SideForm from "./../../components/SideForm"
import behindImageSmall from "../../images/Group 495@2x.png"
import manual_testing from '../../images/manual_testing-01.png'
import mobile_testing from '../../images/mobile_testing-01.png'
import saas_testing from '../../images/saas_testing-01.png'
import usability_testing from '../../images/usability_testing-01.png'
import web_testing from '../../images/web_testing-01.png'
import ServiceCard from "../../components/ServiceCard"
import ServiceStepsProcess from "../../components/ServiceStepsProcess"

import "../../styles/services.scss"
import "../../styles/animate.css"
import FeaturedWork from "../../components/MOB_FeaturedWork"
import IconsAdded from "../../components/IconsAdded"

const { Title } = Typography

const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Quality Assurance Services"}
    descrip = {"Ensure flawless software with our comprehensive quality assurance services. Our expert QA team delivers reliable testing and bug-free solutions. Learn more!"}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/" >
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>

        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>We Offer Transform Your Customer Relationships with Innovative CRM Solutions</Title>
              <br />
              <p>
              Unlock the full potential of your business with our cutting-edge CRM solutions.
              Designed to streamline customer interactions, enhance data management, and drive
              sales growth, our platform offers powerful features like automation, analytics,
              and customizable dashboards.  <br />
                <br />
                Experience improved customer satisfaction and increased efficiency—start
                your journey towards better customer relationships today!
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="services-description-section">
      <div className="service-heading-and-detail">
        <Title>CRM Solution and integration</Title>
      </div>
      <div className="service-cards-wrapper">
        <ServiceCard img_src={manual_testing} img_alt={'manual_testing'} card_heading={'Contact Management'} card_para={'Enhance the way businesses manage customer relationships and interactions. Users can easily update contact details, ensuring that the database reflects the latest information about customers and leads.'}/>
        <ServiceCard img_src={usability_testing} img_alt={'usability_testing'} card_heading={'Lead Management'} card_para={'Effective lead management ensures that leads receive timely responses, which is crucial for maintaining interest and moving them through the sales funnel.'}/>
        <ServiceCard img_src={web_testing} img_alt={'web_testing'} card_heading={'Sales Pipeline Management'} card_para={'Monitoring pipeline metrics allows organizations to recognize trends over time, aiding in strategic planning and resource allocation.'}/>
        <ServiceCard img_src={mobile_testing} img_alt={'mobile_testing'} card_heading={'Task and Activity Management'} card_para={'By organizing tasks effectively, employees can concentrate on high-value activities that contribute significantly to business goals. Enhance productivity, organization, and collaboration within teams and organizations.'}/>
        <ServiceCard img_src={saas_testing} img_alt={'saas_testing'} card_heading={'Reporting and Analytics'} card_para={'Enhance decision-making, improve customer relationships, and optimize business operations. Access to detailed reports helps organizations set realistic goals and develop strategies that align with market demands and customer needs.'}/>
        <ServiceCard card_heading={'Let connect, book a free consultancy session with our experts'} card_button={"Free Consultancy"}/>
      </div>
        <div className="behindimagediv">
            <img
              className="behindImageSmall"
              src={behindImageSmall}
              alt="yellow-triangle"
            />
            <img className="behindImageLarg" src={behindImageSmall} alt="yellow-triangle" />
        </div>
      </div>
      <div className="featured-projects position-relative">
      <FeaturedWork data={data} sharedFeature={{
          Title:"Odoo-based CRM Solution for Banu Mukhtar a construction company",
          descrip1:"The exceptional Odoo-based solution, specially designed to meet Banu Mukhtar's company requirements. Enhanced business processes, optimized productivity, and encountered unmatched ease of use customized just for them.",
          descrip2:"Visiomate provided an ERP solution based on Odoo Community Editionand customized the solution to mold it as per their needs.",
          caseStudyImage:data.crmSolCaseStudy.childImageSharp.fluid
          }}/>
          <IconsAdded/>
      </div>
      <DevelopmentProcess title='CRM Solution and integration Services Process' >
        <ServiceStepsProcess process_heading='Discovery' process_para="A structured meeting designed to align stakeholders on the objectives, requirements, and potential challenges of an CRM project." process_step_number="1" process_indentify="odd" />
        <ServiceStepsProcess process_heading='Environment Setup' process_para="Preparing the necessary hardware and software environments. Ensure the platform allows for customization to match your brand's identity and functional requirements. " process_step_number="2" />
        <ServiceStepsProcess process_heading='User Acceptance Testing (UAT)' process_para="Final validation by end-users to ensure the application meets their needs. Gathering feedback from users to confirm that the application is ready for production." process_step_number="3" process_indentify="odd" iconShow="dark"/>
        <ServiceStepsProcess process_heading='Go-Live' process_para="Going live with your CRM system is a significant milestone that requires careful planning and execution." process_step_number="4" iconShow="dark" />
        <ServiceStepsProcess process_heading='Post Go-Live Support' process_para="Going live with an CRM system is a critical phase that requires effective support to ensure a smooth transition and ongoing operations." process_step_number="5" process_indentify="odd" />
      </DevelopmentProcess>
    </Layout>
  )
}

export default Index

export const  crmSolution= graphql`
  query crmSolution {
    crmSolCaseStudy: file(
      relativePath: { eq: "pf-s73-eve-set-14-mockup 1.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BigJerry: file(relativePath: { eq: "mobile/BigJerryApp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
